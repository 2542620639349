import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import type Store from '@ember-data/store'
import type LocationTrackerService from 're-client/services/location-tracker'
import type Operations from '@blakeelearning/student-operations/operations/service'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type AssignmentsService from 're-client/services/assignments'
import { graphql } from 're-client/graphql'
import { useMutation } from 're-client/resources/mutation'
import type { FeatureService } from '@blakeelearning/features'
import { v4 as uuidV4 } from 'uuid'
import type ErrorHandlerService from 're-client/services/error-handler'

export const saveBookQuizResultMutationDocument = graphql(/* GraphQL */ `
  mutation SaveBookQuizResult($input: BookQuizResultCreateInput!) {
    bookQuizResultCreate(input: $input) {
      bookQuizResult {
        reward {
          eggs
          milestone
          passed
        }
      }
      student {
        id
        eggs
        ...StudentProgressFragment
        ...AssignmentTask
      }
    }
  }
`)
export default class ReaderBookController extends Controller {
  @service
  declare locationTracker: LocationTrackerService

  @service
  declare operations: Operations

  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare store: Store

  @service
  declare assignments: AssignmentsService

  @service
  declare features: FeatureService

  @service
  declare errorHandler: ErrorHandlerService

  saveBookQuizResultMutation = useMutation(
    this,
    saveBookQuizResultMutationDocument,
  )

  get student() {
    return this.user.student
  }

  get studentContext() {
    const { currentTask } = this.assignments

    if (currentTask?.__typename === 'AssignmentTaskLesson') {
      return {
        precinct: 'lessons',
        remoteId: this.student.remoteId,
        taskId: currentTask.id,
        assignmentUuid: currentTask.assignmentUuid,
      }
    }

    return {
      precinct: 'lessons',
      remoteId: this.student.remoteId,
    }
  }

  @action
  next() {
    const { route, params } = this.locationTracker.getPreviousRoute()
    this.router.transitionTo(
      route,
      // @ts-expect-error router params type no bueno
      ...params,
    )
  }

  @action
  resultVideo(videoTitle: string) {
    this.router.transitionTo('reader.result', videoTitle)
  }

  @action
  async saveBookQuizScore(
    code: string,
    totalCount: number,
    correctCount: number,
    uuid?: string,
  ) {
    if (this.features.isEnabled('graphql_book_quiz')) {
      try {
        const result = await this.saveBookQuizResultMutation.current.mutate({
          variables: {
            input: {
              attemptUuid: uuid ?? uuidV4(),
              bookCode: code,
              correctCount,
              totalCount,
            },
          },
        })

        if (result?.bookQuizResultCreate.bookQuizResult.reward.passed) {
          // NOTE: Once user has successfully read a book & passed the quiz we know
          // this book should be shown on the bookshelf, hence instead of
          // reloading the bookshelf route every time, it is better to do
          // it here. reload() is more reliable than { reload: true }
          // findRecord will fetch cached data & reload() will refetch the request
          const bookshelf = await this.store.findRecord(
            'bookshelf',
            'bookshelf',
          )
          await bookshelf.reload()
        }
        return result?.bookQuizResultCreate.bookQuizResult.reward
      } catch (error) {
        this.errorHandler.handleError(
          '[SaveBookQuizResult] mutation failed',
          error,
        )
        return
      }
    } else {
      // @ts-expect-error - book quizzes do not have a lesson or activity number
      const response = await this.operations.completeBookQuiz(
        this.studentContext,
        code,
        correctCount,
        totalCount,
      )
      const {
        eligible,
        reward: { milestone, eggs, passed },
      } = response as {
        eligible: boolean
        reward: { milestone: number; eggs: number; passed: boolean }
      }

      if (passed) {
        this.user.incrementEggs(eggs)

        // NOTE: Once user has successfully read a book & passed the quiz we know
        // this book should be shown on the bookshelf, hence instead of
        // reloading the bookshelf route every time, it is better to do
        // it here. reload() is more reliable than { reload: true }
        // findRecord will fetch cached data & reload() will refetch the request
        const bookshelf = await this.store.findRecord('bookshelf', 'bookshelf')
        await bookshelf.reload()
      }
      await this.assignments.fetch()
      return { passed, eligible, milestone }
    }
  }
}
