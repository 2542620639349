import { http, HttpResponse } from 'msw'
import type { RequestHandler } from 'msw'
import {
  BadgeColourEnum,
  CertificateColourEnum,
  mockAcknowledgeQuestGoalAlertMutation,
  mockCreateReadingGoalsQuizResultMutation,
  mockGetCertificatesQuery,
  mockGetInitialAppDataQuery,
  mockGetMyAwardsQuery,
  mockGetPendingCertificateQuery,
  mockGetPendingCertificateUploadUrlQuery,
  mockGetPlazaPollsQuery,
  mockGetReadingGoalsQuizRecordQuery,
  mockGetReadingGoalsQuizzesQuery,
  mockGetStudentCurrentTaskQuery,
  mockGetStudentEssentialQuestQuery,
  mockGetStudentQuery,
  mockGetStudentQuestQuery,
  mockReadingActivitiesQuery,
  mockResetReadingLessonProgressMutation,
  mockResetReadingMapQuizProgressMutation,
  mockResetReadingPlacementTestProgressMutation,
  mockSaveBookQuizResultMutation,
  mockSaveCertificateMutation,
  mockSaveReadingLessonActivityResultMutation,
  mockSaveReadingLessonPlacementTestResultMutation,
  mockSaveReadingMapQuizResultMutation,
  mockSaveSkillsQuizResultMutation,
  mockSaveSpellingLessonActivityResultMutation,
  mockSaveSpellingLessonQuizResultMutation,
  mockStudentProgressQuery,
  QuestGoalStatusEnum,
  type PendingCertificateFragmentFragment,
} from 're-client/graphql/graphql'
import { db } from './db'
import config from 're-client/config/environment'

export const scenarios: Record<string, RequestHandler[]> = {
  unauthenticated: [
    http.get('/auth/session', () => {
      return HttpResponse.json({
        data: {},
      })
    }),
    mockGetInitialAppDataQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: null,
        },
      })
    }),
    mockReadingActivitiesQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          readingActivities: db.getReadingActivities(),
        },
      })
    }),
  ],
  default: [
    mockGetInitialAppDataQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetStudentQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockReadingActivitiesQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          readingActivities: db.getReadingActivities(),
        },
      })
    }),
    mockStudentProgressQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetStudentQuestQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetStudentCurrentTaskQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetStudentEssentialQuestQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetPendingCertificateQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetCertificatesQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetMyAwardsQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetPendingCertificateQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockGetPlazaPollsQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          plazaPolls: db.getPlazaPolls(),
          student: db.getStudent(),
        },
      })
    }),
    mockGetPendingCertificateUploadUrlQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          getPendingCertificate: db.getPendingCertificateUploadUrl(),
        },
      })
    }),
    mockCreateReadingGoalsQuizResultMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }
      const { answers, quizId } = variables.input
      const passed =
        answers.filter((answer) => answer.correct).length / answers.length >=
        0.85
      const eggsReward = passed ? 10 : 0

      student.eggs += eggsReward

      db.createReadingGoalsQuizSummary({
        attemptsThisWeek: 1,
        eggsReward,
        id: quizId,
        passed,
        secondsToPass: 0,
        timestamp: new Date().toISOString(),
      })

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          readingGoalsQuizResultCreate: {
            __typename: 'ReadingGoalsQuizResultCreateOutput',
            student,
            readingGoalsQuizResult: {
              __typename: 'ReadingGoalsQuizResult',
              eggsReward,
              passed,
            },
          },
        },
      })
    }),
    mockGetReadingGoalsQuizzesQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          readingGoalsQuizzes: db.getReadingGoalQuizzes(),
        },
      })
    }),
    mockAcknowledgeQuestGoalAlertMutation(({ variables }) => {
      const goal = db.acknowledgeQuestGoal(variables.input.goalId)

      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      if (goal) {
        student.eggs += goal.rewardAmount
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          studentQuestGoalAcknowledgeComplete: {
            __typename: 'StudentQuestGoalAcknowledgeCompletePayload',
            student,
          },
        },
      })
    }),
    mockGetReadingGoalsQuizRecordQuery(() => {
      return HttpResponse.json({
        data: {
          __typename: 'Query',
          student: db.getStudent(),
        },
      })
    }),
    mockSaveCertificateMutation(({ variables }) => {
      const certificate = db.saveCertificate(variables.input)
      const student = db.getStudent()

      if (!student || !certificate) {
        return HttpResponse.json({
          data: null,
        })
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          certificateSave: {
            __typename: 'CertificateSavePayload',
            student,
            certificate,
          },
        },
      })
    }),
    mockSaveSkillsQuizResultMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      const { answers, assignment, lessonInPrecinct } = variables.input
      const correctCount = answers.filter((answer) => answer.isCorrect).length
      const passed = correctCount >= 8

      /**
       * For now these values are hardcoded here.
       * We could create some quiz result records in the mirage db to enable us to react to multiple attempts like the real backend.
       * But since the logic around attempts and auto passing is a bit complex this is good enough for now.
       */
      const attempt = 1
      const autoPassed = false
      const eggsEarned = passed ? 10 : 0

      if (assignment) {
        const { assignmentTaskId } = assignment

        db.deleteAssignmentTask(assignmentTaskId.toFixed())
      } else {
        // Update quest goal progress
        const questGoal = student.quest?.primaryGoal

        if (questGoal?.__typename === 'QuestGoalLesson') {
          questGoal.progressCurrent = questGoal.progressCurrent + 1
        }
      }

      if (passed) {
        // Update progress. In reality this would not happen if the student has already progressed further than this activity.
        db.updateProgress((previous) => ({
          ...previous,
          lessons: {
            ...previous.lessons,
            currentActivity: previous.lessons.currentActivity + 1,
          },
        }))

        // Lets work out the badges :)
        const badgeColourScores = new Map([
          [BadgeColourEnum.Bronze, 8],
          [BadgeColourEnum.Silver, 9],
          [BadgeColourEnum.Gold, 10],
        ])

        const bestPreviousBadge = db.findBadge(
          (badge) => badge.lesson === lessonInPrecinct,
        )
        const bestPreviousBadgeColour = bestPreviousBadge?.colour

        let bestPreviousScore = 0

        if (bestPreviousBadgeColour) {
          bestPreviousScore =
            badgeColourScores.get(bestPreviousBadgeColour) ?? 0
        }

        // we only need to make a badge and update the student if the new score is better
        if (correctCount > bestPreviousScore) {
          let colour
          for (const [badgeColor, score] of badgeColourScores) {
            if (correctCount === score) {
              colour = badgeColor
            }
          }

          if (colour) {
            db.createBadge({
              colour,
              lesson: lessonInPrecinct,
            })
          }
        }
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          readingEndOfLessonQuizResultCreate: {
            __typename: 'ReadingEndOfLessonQuizResultCreateOutput',
            student,
            readingEndOfLessonQuizResult: {
              __typename: 'ReadingEndOfLessonQuizResult',
              attempt,
              autoPassed,
              passed,
              eggsEarned,
              lessonInPrecinct,
            },
          },
        },
      })
    }),
    mockSaveReadingLessonActivityResultMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      const { assignment, lessonInPrecinct, activityInLesson } = variables.input

      if (assignment) {
        const { assignmentTaskId } = assignment

        db.deleteAssignmentTask(assignmentTaskId.toFixed())
      } else {
        // Update progress. In reality this would not happen if the student has already progressed further than this activity.
        db.updateProgress((previous) => ({
          ...previous,
          lessons: {
            ...previous.lessons,
            currentActivity: previous.lessons.currentActivity + 1,
          },
        }))

        const questGoal = student.quest?.primaryGoal

        if (
          questGoal?.__typename === 'QuestGoalLesson' &&
          questGoal.lesson === lessonInPrecinct &&
          questGoal.progressCurrent < activityInLesson
        ) {
          const status =
            activityInLesson === questGoal.progressTotal
              ? QuestGoalStatusEnum.ShowCompleteAlert
              : QuestGoalStatusEnum.Incomplete
          db.updateQuestGoal(questGoal, {
            progressCurrent: activityInLesson,
            status,
          })
        }
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          readingLessonActivityResultCreate: {
            __typename: 'ReadingLessonActivityResultCreateOutput',
            student,
            readingLessonActivityResult: {
              __typename: 'ReadingLessonActivityResult',
              reward: {
                __typename: 'ReadingLessonActivityReward',
                eggs: 10,
              },
            },
          },
        },
      })
    }),
    mockResetReadingLessonProgressMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      const { lesson, activity } = variables.input

      const progressInfoForLessons = config.studentProgress.progress
      const { lessonsPerMap } = progressInfoForLessons.lessons
      const mapNumber = Math.ceil(lesson / lessonsPerMap)

      db.updateProgress((previous) => ({
        ...previous,
        lessons: {
          ...previous.lessons,
          currentActivity: activity,
          currentLesson: lesson.toString(),
          currentMap: mapNumber,
          showPlacementTest: false,
        },
      }))

      const questGoal = student.quest?.primaryGoal

      if (questGoal) {
        db.updateQuestGoal(questGoal, {
          lesson: lesson,
          progressCurrent: activity - 1,
          progressTotal: 10,
          rewardAmount: 20,
          status: QuestGoalStatusEnum.Incomplete,
          goalType: 'QuestGoalLesson',
          __typename: 'QuestGoalLesson',
        })
      } else {
        db.createQuestGoal({
          lesson,
          progressCurrent: activity - 1,
          progressTotal: 10,
          rewardAmount: 20,
          status: QuestGoalStatusEnum.Incomplete,
          __typename: 'QuestGoalLesson',
        })
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          studentProgressReadingLessonReset: {
            __typename: 'StudentProgressReadingLessonResetPayload',
            student,
          },
        },
      })
    }),
    mockResetReadingMapQuizProgressMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      const { map } = variables.input

      db.updateProgress((previous) => ({
        ...previous,
        lessons: {
          ...previous.lessons,
          currentActivity: 1,
          currentLesson: 'quiz',
          currentMap: map,
          showPlacementTest: false,
        },
      }))

      const questGoal = student.quest?.primaryGoal

      if (questGoal) {
        db.updateQuestGoal(questGoal, {
          map,
          progressCurrent: 0,
          progressTotal: 1,
          rewardAmount: 20,
          status: QuestGoalStatusEnum.Incomplete,
          goalType: 'QuestGoalMap',
          __typename: 'QuestGoalMap',
        })
      } else {
        db.createQuestGoal({
          map,
          progressCurrent: 0,
          progressTotal: 1,
          rewardAmount: 20,
          status: QuestGoalStatusEnum.Incomplete,
          __typename: 'QuestGoalMap',
        })
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          studentProgressReadingMapQuizReset: {
            __typename: 'StudentProgressReadingMapQuizResetPayload',
            student,
          },
        },
      })
    }),
    mockResetReadingPlacementTestProgressMutation(() => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      db.updateProgress((previous) => ({
        ...previous,
        lessons: {
          ...previous.lessons,
          currentActivity: 1,
          currentLesson: '1',
          currentMap: 1,
          showPlacementTest: true,
        },
      }))

      db.deleteQuest()

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          studentProgressReadingPlacementTestReset: {
            __typename: 'StudentProgressReadingPlacementTestResetPayload',
            student,
          },
        },
      })
    }),
    mockSaveReadingLessonPlacementTestResultMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      const {
        lesson,
        skippedTest: _skippedTest,
        attemptUuid: _attemptUuid,
      } = variables.input

      const progressInfoForLessons = config.studentProgress.progress
      const { lessonsPerMap } = progressInfoForLessons.lessons
      const mapNumber = Math.ceil(lesson / lessonsPerMap)

      db.updateProgress((previous) => ({
        ...previous,
        lessons: {
          ...previous.lessons,
          currentActivity: 1,
          currentLesson: lesson.toString(),
          currentMap: mapNumber,
          showPlacementTest: false,
        },
      }))

      const questGoal = student.quest?.primaryGoal

      if (questGoal) {
        db.updateQuestGoal(questGoal, {
          lesson: lesson,
          progressCurrent: 0,
          progressTotal: 10,
          rewardAmount: 20,
          status: QuestGoalStatusEnum.Incomplete,
          goalType: 'QuestGoalLesson',
          __typename: 'QuestGoalLesson',
        })
      } else {
        db.createQuestGoal({
          lesson,
          progressCurrent: 0,
          progressTotal: 10,
          rewardAmount: 20,
          status: QuestGoalStatusEnum.Incomplete,
          __typename: 'QuestGoalLesson',
        })
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          readingLessonPlacementTestResultCreate: {
            __typename: 'ReadingLessonPlacementTestResultCreateOutput',
            student,
          },
        },
      })
    }),
    mockSaveReadingMapQuizResultMutation(({ variables }) => {
      const student = db.getStudent()

      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      const { correctAnswers, quiz } = variables.input

      const correctCount = correctAnswers.length

      const pendingCertForScore: Record<
        number,
        Omit<
          PendingCertificateFragmentFragment,
          'id' | '__typename' | 'quizResultCreatedAt' | 'map'
        >
      > = {
        11: { colour: CertificateColourEnum.Bronze, scorePercentage: 73 },
        12: { colour: CertificateColourEnum.Silver, scorePercentage: 80 },
        13: { colour: CertificateColourEnum.Silver, scorePercentage: 87 },
        14: { colour: CertificateColourEnum.Gold, scorePercentage: 93 },
        15: { colour: CertificateColourEnum.Gold, scorePercentage: 100 },
      }

      const pendingCertData = pendingCertForScore[correctCount]

      if (pendingCertData) {
        db.createPendingCertificate({
          ...pendingCertData,
          map: quiz,
        })

        const progressInfoForLessons = config.studentProgress.progress
        const { lessonsPerMap } = progressInfoForLessons.lessons
        const lesson = lessonsPerMap * quiz + 1
        const map = quiz + 1

        db.updateProgress((previous) => ({
          ...previous,
          lessons: {
            ...previous.lessons,
            currentActivity: 1,
            currentLesson: lesson.toString(),
            currentMap: map,
            showPlacementTest: false,
          },
        }))

        const questGoal = student.quest?.primaryGoal
        if (
          questGoal?.__typename === 'QuestGoalMap' &&
          questGoal.map === quiz &&
          questGoal.status === QuestGoalStatusEnum.Incomplete
        ) {
          db.updateQuestGoal(questGoal, {
            progressCurrent: 1,
            status: QuestGoalStatusEnum.ShowCompleteAlert,
          })
        }
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          readingMapQuizResultCreate: {
            __typename: 'ReadingMapQuizResultCreateOutput',
            student,
          },
        },
      })
    }),
    mockSaveSpellingLessonActivityResultMutation(({ variables }) => {
      const student = db.getStudent()
      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }
      const { assignment, activityInLesson } = variables.input
      if (assignment) {
        const { assignmentTaskId } = assignment
        db.deleteAssignmentTask(assignmentTaskId.toFixed())
      } else {
        // Update progress. In reality this would not happen if the student has already progressed further than this activity.
        db.updateProgress((previous) => ({
          ...previous,
          spelling: {
            ...previous.spelling,
            currentActivity: activityInLesson + 1,
          },
        }))
      }
      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          spellingLessonActivityResultCreate: {
            __typename: 'SpellingLessonActivityResultCreateOutput',
            student,
            spellingLessonActivityResult: {
              __typename: 'SpellingLessonActivityResult',
              reward: {
                __typename: 'SpellingLessonActivityReward',
                eggs: 10,
              },
            },
          },
        },
      })
    }),
    mockSaveSpellingLessonQuizResultMutation(({ variables }) => {
      const student = db.getStudent()
      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }
      const { assignment, lesson, correctAnswers } = variables.input

      const correctCount = correctAnswers.length

      const passed = correctCount >= 8

      let shop = passed

      if (assignment) {
        const { assignmentTaskId } = assignment
        db.deleteAssignmentTask(assignmentTaskId.toFixed())
        shop = false
      } else {
        // Update progress. In reality this would not happen if the student has already progressed further than this activity.
        db.updateProgress((previous) => ({
          ...previous,
          spelling: {
            ...previous.spelling,
            currentLesson: (lesson + 1).toString(),
            currentActivity: 1,
          },
        }))
      }
      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          spellingLessonQuizResultCreate: {
            __typename: 'SpellingLessonQuizResultCreateOutput',
            student,
            spellingLessonQuizResult: {
              __typename: 'SpellingLessonQuizResult',
              passed,
              reward: {
                __typename: 'SpellingLessonQuizReward',
                eggs: passed ? 10 : 0,
                shop: shop,
              },
            },
          },
        },
      })
    }),
    mockSaveBookQuizResultMutation(({ variables }) => {
      const { correctCount, totalCount } = variables.input

      const passed = correctCount / totalCount >= 0.8
      const eggs = passed ? 10 : 0

      if (passed) db.incrementStudentEggs(eggs)

      const student = db.getStudent()
      if (!student) {
        return HttpResponse.json({
          data: null,
        })
      }

      return HttpResponse.json({
        data: {
          __typename: 'Mutation',
          bookQuizResultCreate: {
            __typename: 'BookQuizResultCreateOutput',
            student,
            bookQuizResult: {
              __typename: 'BookQuizResult',
              reward: {
                __typename: 'BookQuizReward',
                eggs,
                milestone: false,
                passed,
              },
            },
          },
        },
      })
    }),
  ],
}
