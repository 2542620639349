/* eslint-disable ember/no-test-import-export */
import type { FeatureService } from '@blakeelearning/features'
import type Operations from '@blakeelearning/student-operations/operations/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import type RouterService from '@ember/routing/router-service'
import { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import { graphql } from 're-client/graphql'
import { useMutation } from 're-client/resources/mutation'
import type DebugModeService from 're-client/services/debug-mode'
import type ErrorHandlerService from 're-client/services/error-handler'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import { debugAction } from 're-client/utils/debug'
import { v4 as uuidV4 } from 'uuid'

const {
  APP: { product },
} = config

export const saveReadingLessonPlacementTestResultMutationDocument = graphql(
  /* GraphQL */ `
    mutation SaveReadingLessonPlacementTestResult(
      $input: ReadingLessonPlacementTestResultCreateInput!
    ) {
      readingLessonPlacementTestResultCreate(input: $input) {
        student {
          id
          ...StudentProgressFragment
          ...AssignmentTask
          ...StudentQuestDataFragment
          ...QuestGoalEssentialDataFragment
        }
      }
    }
  `,
)
export default class LessonsPlacementTestController extends Controller {
  @service
  declare debugMode: DebugModeService

  @service
  declare operations: Operations

  @service
  declare studentProgress: StudentProgressService

  @service
  declare router: RouterService

  @service
  declare user: UserService

  @service
  declare features: FeatureService

  @service
  declare errorHandler: ErrorHandlerService

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  saveReadingLessonPlacementTestResultMutation = useMutation(
    this,
    saveReadingLessonPlacementTestResultMutationDocument,
  )

  get studentContext() {
    return {
      product,
      precinct: 'lessons',
      remoteId: this.user.student.remoteId,
    }
  }

  @action
  next() {
    const currentMap = this.studentProgress.lessonsCurrentMap
    if (currentMap === 1) {
      this.router.transitionTo('lessons.map', currentMap)
    } else {
      this.router.transitionTo('my-program')
    }
  }

  @action
  async placementTestComplete(
    lessonId: number,
    skipped?: boolean,
    uuid?: string,
  ) {
    if (this.features.isEnabled('graphql_placement_test')) {
      try {
        await this.saveReadingLessonPlacementTestResultMutation.current.mutate({
          variables: {
            input: {
              attemptUuid: uuid ?? uuidV4(),
              lesson: lessonId,
              skippedTest: skipped ?? false,
            },
          },
        })
        this.interactive.callInteractionMethod('nextable')
      } catch (error) {
        this.errorHandler.handleError(
          '[SaveReadingLessonPlacementTestResult] mutation failed',
          error,
        )
      }
    } else {
      const { studentContext } = this
      await this.operations.completePlacement(studentContext, lessonId, skipped)
      const response = await this.studentProgress.fetchProgress()
      const data = { reward: { eggs: 0 }, ...response }
      this.interactive.callInteractionMethod('nextable', data)
    }
  }

  @action
  @debugAction({
    lessonId: {
      type: 'number',
      value: '1',
    },
  })
  async completePlacementTest({ lessonId }: { lessonId: number }) {
    await this.placementTestComplete(lessonId)
    this.next()
  }

  @action
  @debugAction()
  async skipPlacementTest() {
    await this.placementTestComplete(1, true)
    this.next()
  }
}

declare module '@ember/controller' {
  interface Registry {
    'lessons/placement-test': LessonsPlacementTestController
  }
}
